import React from 'react';
import './css/Profile.css';

const Profile = ({ username, firstName, lastName, email, onUsernameChange, onFirstNameChange, onLastNameChange }) => {
  return (
    <div className="profile-container">
      <div className="image-container">
        <img src="path_to_your_image" alt="Profile" />
      </div>
      <h1 className="profile-title">Profile</h1>
      <form className="profile-form">
        <label>
          Username
          <input type="text" value={username} onChange={onUsernameChange} />
        </label>
        <label>
          First Name
          <input type="text" value={firstName} onChange={onFirstNameChange} />
        </label>
        <label>
          Last Name
          <input type="text" value={lastName} onChange={onLastNameChange} />
        </label>
        <label>
          Email
          <input type="email" value={email} readOnly />
        </label>
      </form>
    </div>
  );
};

export default Profile;