////////////////////////////////////////////////////////////////////////////////////
// IMPORTS
import React, { useState, useEffect, useCallback } from 'react';
import './css/Tasks.css';
import Selection from './Selection';
////////////////////////////////////////////////////////////////////////////////////
// LOCAL TESTING

////////////////////////////////////////////////////////////////////////////////////
// FUNCTIONS
export const Tasks = () => {
  const [showModal, setShowModal] = useState(false);
  const [newTask, setNewTask] = useState({ name: '', type: 4 });
  const [tasks, setTasks] = useState([]);
  const [userID] = useState(1); //setUserID
  const [selectedTaskId, setSelectedTaskId] = useState(null);
////////////////////////////////////////////////////////////////////////////////////
// FETCH DATA FROM SERVER:
const fetchTasks = useCallback(async () => {
  console.log(`Fetching tasks for userId: ${userID}`);
  console.log(`Type of userId: ${typeof userID}`);
  
  const res = await fetch(`http://localhost:8000/api/get-tasks?userID=${userID}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  
  const data = await res.json();
  console.log('Data from API:', data);

  if (Array.isArray(data)) {
    const tasks = data.map((taskTuple) => ({
      id: taskTuple[0],
      name: taskTuple[1],
      assignee_id: taskTuple[2],
    }));

    setTasks(tasks);
  } else {
    console.error('Data from API is not an array:', data);
  }
}, [userID]);
  
  const handleInputChange = (event) => {
    const value = event.target.name === 'type' ? parseInt(event.target.value) : event.target.value;
    setNewTask({ ...newTask, [event.target.name]: value });
  }

  const handleSelectTask = (taskId) => {
    console.log(`Task with ID ${taskId} was selected by ${userID}`); 
    setSelectedTaskId(taskId);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const userEmail = 'create_task@knowa.com'
    const payload = {
      user: {
        id: userID,
        email: userEmail
      },
      task: {
        id: tasks.length,
        name: newTask.name,
        entitytype_id: parseInt(newTask.type),
      }
    };
  
    console.log('Payload:', payload);

    fetch('http://localhost:8000/api/create-task', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => {
      console.log('Response:', response);
      if (!response.ok) {
        console.log('Response text:', response.text());
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log('Data:', data);
      if (data.message === 'Task created successfully') {
        const newTask = {
          id: tasks.length,
          name: payload.task.name,
          assignee_id: payload.user.id,
        };
        setTasks([...tasks, newTask]);
      }
      setNewTask({ name: '', type: '' });
      setShowModal(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      if (error.message.includes('422')) {
    
        console.error('Unprocessable Entity');
      }
    });
  };

  const handleOpenModal = () => {
    setShowModal(true);};
  // NOT IN USE //////////////////////////////////////////////////////////////////////
  // Add IF CLICK OFF object as ISSUE
  //const handleCloseModal = () => {
  //  setShowModal(false);
  //};
/////////////////////////////////////////////////////////////////////////////////////
// EFFECTS
  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);
/////////////////////////////////////////////////////////////////////////////////////
//// UI
  return (
    <div className="tasks-container">
      <button onClick={handleOpenModal} title="Add Task">(+)</button>
      {showModal && (
        <div className="task-modal">
          <form onSubmit={handleSubmit}>
            <input 
              type="text" 
              name="name"
              value={newTask.name} 
              onChange={handleInputChange} 
              placeholder="New task" 
            />
            <select name="type" value={newTask.type} onChange={handleInputChange}>
              <option value={4}>Task</option>
              <option value={5}>Process</option>
              <option value={3}>Project</option>
              <option value={2}>Objective</option>
              <option value={6}>Risk Event</option>
              <option value={1}>Company Goal</option>
            </select>
            <button type="submit">Add</button>
          </form>
        </div>
      )}
      {tasks.map((task) => (
        <div 
          //key={task.id} 
          className={`tasks-tasks ${task.id === selectedTaskId ? 'selected' : ''}`}
          onClick={() => handleSelectTask(task.id)}
        >
          {task.id} - {task.name} - {task.assignee_id}
        </div>
      ))}
      <Selection selectedTaskId={selectedTaskId} />
    </div>
  );
};

export default Tasks;
