import React, { useEffect, useState } from "react";

export const Selection = ({ selectedTaskId }) => {
    const [taskDetails, setTaskDetails] = useState(null);

    useEffect(() => {
        console.log('Running useEffect hook. selectedTaskId:', selectedTaskId);

        if (selectedTaskId !== null && selectedTaskId !== undefined) {
            const url = 'http://localhost:8000/api/get-entity-selection';
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: selectedTaskId,
                    entitytype_id: 0,
                    name: "",
                }),
            };

            console.log('Sending request to:', url, 'with options:', options);

            fetch(url, options)
                .then(response => {
                    console.log('Received response:', response);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Payload:', data);
                    if (data && data.length > 0) {
                        const taskData = data[0];
                        const taskObject = {
                            id: taskData.id,
                            created_at: taskData.created_at,
                            created_by: taskData.created_by,
                            entitytype_id: taskData.entitytype_id,
                            assignee_id: taskData.assignee_id,
                            name: taskData.name,
                            rank: taskData.rank,
                        };
                        console.log('Setting taskDetails:', taskObject);
                        setTaskDetails(taskObject);
                    } else {
                        console.log('No task details in response');
                        setTaskDetails(null);
                    }
                })
                .catch(error => {
                    console.error('Error fetching task details:', error);
                    setTaskDetails(null);
                });
        } else {
            console.log('No selectedTaskId');
            setTaskDetails(null);
        }
    }, [selectedTaskId]);

    useEffect(() => {
        console.log('taskDetails state updated:', taskDetails);
    }, [taskDetails]);

    return (
        <div className="selection-container">
            {taskDetails ? (
                <div>
                    <h2>Name: {taskDetails.name}</h2>
                    <p>Time: {taskDetails.created_at ? new Date(taskDetails.created_at).toLocaleString() : ''}</p>
                    <p>Description: {taskDetails.created_by}</p>
                    <p>Entity Type: {taskDetails.entitytype_id}</p>
                    <p>Assignee: {taskDetails.assignee_id}</p>
                </div>
            ) : (
                <p>No task selected</p>
            )}
        </div>
    );
};

export default Selection;
