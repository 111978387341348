////////////////////////////////////////////////////////////////////////////////////////
// IMPORTS
import './css/Dashboard.css';
import Chat from './Chat';
import NodeGraph from './Nodegraph';
import Misc from './Misc';
import Tasks from './Tasks';
import ChatModal from './ChatModal';
import Selection from './Selection';
import Objective from './Objective';
////////////////////////////////////////////////////////////////////////////////////////
// MAIN
const DashboardPage = () => {
  return (
    <div className="dashboard-container">
      <Misc />
      <Tasks />
      <Chat />
      <ChatModal />
      <NodeGraph />
      <Selection />
      <Objective />
    </div>
  );
};

export default DashboardPage;