////////////////////////////////////////////////////////////////////////////////
// IMPORT
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import logo from './config/logo.svg';
import './App.css';
import Profile from './pages/Profile';
import Dashboard from './pages/Dashboard';
////////////////////////////////////////////////////////////////////////////////
// MAIN
const Home = () => {
  const [email, setEmail] = useState('');
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const isValidEmail = /\S+@\S+\.\S+/.test(email);
    if (!isValidEmail) {
      alert('Please enter a valid email address.');
      return;
    }
  
    setSubmittedEmail(email);
  
    fetch('http://localhost:8000/api/create-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then(response => response.json())
      .then(data => {
        setMessage(data.message);
        if (data.redirect) {
          window.location.href = data.redirect;
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  
    setEmail('');
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <code>Pickled Kimchi</code>
        </p>
        <form onSubmit={handleSubmit}>
          <input style={{width: '200px'}} type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
          <button type="submit">Submit</button>
        </form>
        <p>{submittedEmail && `Submitted email: ${submittedEmail}`}</p>
        <p> {message} </p>
        <Link to="/profile">Login</Link>
        <Link to="/dashboard">Dashboard</Link>
      </header>
    </div>
  );
};

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/dashboard" element={<Dashboard />} />
    </Routes>
  </Router>
);

export default App;