import React from 'react';

export const ChatModal = () => {
    return (
      <div>
      <div className="chat-modal-prompt">
        ChatModal Component
      </div>
      <div className="chat-modal-response-yes">
        Yes
      </div>
      <div className="chat-modal-response-no">
        No
      </div>
      </div>
    );
  };

  export default ChatModal;