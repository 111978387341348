import React from "react";
import { Link } from 'react-router-dom'; 

export const Misc = () => {
    return (
      <div className="misc-container">
        Misc Component
        <Link to="/profile">
          <img src="https://via.placeholder.com/50" alt="Profile Icon" className="profile-icon" />
        </Link>
      </div>
    );
  };

  export default Misc;