import React, { useState, useEffect, useRef } from 'react';
import './css/Chat.css';

export const Chat = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);

  // Inside your Chat component
  const chatHistoryRef = useRef(null);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const fetchChatHistory = () => {
    fetch('http://localhost:8000/api/get-messages')
      .then(response => response.json())
      .then(data => {
        console.log('Data from API:', data); // Log the data from the API
        if (Array.isArray(data) && data.length > 0) {
          setChatHistory(data);
        } else {
          console.error('Error: expected a non-empty array but received', data);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    fetch('http://localhost:8000/api/create-message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        user: { id: 1, email: "example@example.com" },
        message: { message }
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setMessage('');
        setTimeout(fetchChatHistory, 1000); 
      })
      .catch((error) => {
        console.error('Error:', error);
        setError('An error occurred while sending the message.');
      });
  };

  return (
    <div>
      <div className="chat chat-history" ref={chatHistoryRef}>
        {chatHistory.map((chatMessage, index) => {
          const [, messageContent, userId] = chatMessage;
          const messageClass = userId === 76 ? 'message-from-user-76' : 'message-from-other-users';
          return (
            <div key={index} className={messageClass}>
              User {userId}: {messageContent}
            </div>
          );
        })}
      </div>
      <form className="chat-input" onSubmit={handleSubmit}>
        <textarea 
          placeholder="Type a message..." 
          value={message} 
          onChange={handleInputChange} 
          rows={2}
        />
        <button type="submit" className="submit-button">➤</button>
      </form>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default Chat;