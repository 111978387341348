import React, { useEffect, useRef, useState } from 'react';
import { Chart, BubbleController, LinearScale, PointElement } from 'chart.js';
import './css/Nodegraph.css';

Chart.register(BubbleController, LinearScale, PointElement);

function NodeGraph() {
    const canvasRef = useRef();
    const chartRef = useRef();
    const [data, setData] = useState([]);
    const [isRendering, setIsRendering] = useState(false);

  useEffect(() => {
    fetch('/api/get-messages', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
        console.log('Server data:', data);
        const userIds = [...new Set(data.map(item => item[3]))];
        const userIdToNumber = Object.fromEntries(userIds.map((userId, index) => [userId, index + 1]));
        const transformedData = data.map((item, index) => {
          return {
            message: item[1],
            userId: userIdToNumber[item[3]],
            frequency: index // replace this with actual frequency if available
          };
        });
        console.log('Transformed data:', transformedData);
        setData(transformedData);
    })
    .catch((error) => {
      console.error('Fetch error:', error);
      // Use hardcoded data if the fetch request fails
      setData([
        { message: 'message1', userId: '1', frequency: 1 },
        { message: 'message2', userId: '2', frequency: 2 },
        { message: 'message3', userId: '3', frequency: 1 },
        { message: 'message4', userId: '4', frequency: 2 },
        { message: 'message5', userId: '5', frequency: 1 },
        { message: 'message6', userId: '6', frequency: 2 },
        { message: 'message7', userId: '7', frequency: 1 },
        { message: 'message8', userId: '8', frequency: 2 },
        { message: 'message5', userId: '5', frequency: 1 },
        { message: 'message6', userId: '6', frequency: 2 },
        { message: 'message7', userId: '7', frequency: 1 },
        { message: 'message8', userId: '8', frequency: 2 },
        { message: 'message5', userId: '5', frequency: 1 },
        { message: 'message6', userId: '6', frequency: 2 },
        { message: 'message7', userId: '7', frequency: 1 },
        { message: 'message8', userId: '8', frequency: 2 },
        { message: 'message5', userId: '5', frequency: 1 },
        { message: 'message6', userId: '6', frequency: 2 },
        { message: 'message7', userId: '7', frequency: 1 },
        { message: 'message8', userId: '8', frequency: 10 },
      ]);
    });
  }, []);
  
  useEffect(() => {
    if (!data.length || isRendering) {
      console.log('Data is not available yet or chart is currently being rendered');
      return;
    }

    setIsRendering(true);

    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) {
      console.error('Canvas context not available');
      setIsRendering(false);
      return;
    }

    // Set the canvas dimensions to the dimensions of its parent element
   canvasRef.current.width = canvasRef.current.parentElement.offsetWidth;
   canvasRef.current.height = canvasRef.current.parentElement.offsetHeight;

    if (chartRef.current) {
      chartRef.current.destroy();
    }
  
    chartRef.current = new Chart(ctx, {
        type: 'bubble',
        data: {
          datasets: data.map((item, index) => ({
            label: item.message,
            data: [{
              x: index,
              y: item.userId, // this should now be a number
              r: item.frequency * 10 // adjust this to get the right bubble size
            }],
            backgroundColor: `hsla(${index * 50}, 50%, 50%, 0.5)`
          }))
        },
        options: {
          scales: {
            yAxes: [{ ticks: { beginAtZero: true } }]
          },
          maintainAspectRatio: false // add this line
        }
      });
  
    if (chartRef.current) {
        console.log('Chart created:', chartRef.current);
      } else {
        console.error('Chart not created');
      }
  
      setIsRendering(false);
    }, [data, isRendering]);
    
    return <canvas ref={canvasRef} className="nodegraph-container" />;
}

export default NodeGraph;